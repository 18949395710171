import React from "react"
import { Link } from "gatsby";
import Animated from "../animated"
import "../../style/home/home.scss"

const Leftsite = () =>
{
  const [hamburger, setHamburger] = React.useState(false);
  const [show, setShow] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [rotate, setRotate] = React.useState(null);


  React.useEffect(() =>
  {
    const isSmall = window.innerWidth <= 650;
    setHamburger(isSmall);
    setShow(!isSmall);
    window.addEventListener("resize", onResize);

  }, [])

  React.useEffect(() =>
  {
    let timeout
    if (timeout)
    {
      clearTimeout(timeout)
    }
    if (open)
    {
      timeout = setTimeout(() =>
      {
        setShow(false);
        setOpen(false)
      }, 700);
    }
    return () =>
    {
      clearTimeout(timeout);
    }
  }, [open])

  const onResize = () =>
  {
    const isSmall = window.innerWidth <= 650;
    if (!hamburger)
    {
      setHamburger(isSmall)
      setShow(!isSmall);
      setRotate(false);
    }
  }


  const toggleMenu = (e) =>
  {
    toggleIcon();
    if (show)
    {
      setOpen(!open)
    } else
    {
      setShow(true)
    }
  }

  const toggleIcon = () =>
  {
    setRotate(!rotate)
  }

  const onBlur = (e) =>
  {
    if (e.relatedTarget && e.target)
      if (e.relatedTarget.tabIndex !== e.target.tabIndex)
      {
        if (rotate)
        {
          toggleMenu(e)
        }
      }
  }

  return (
    <aside onBlur={onBlur} tabIndex={0} className={hamburger ? "hamburger" : ""}>
      {hamburger && <button tabIndex={0} className={rotate ? "btnClicked" : "btnUnClicked"} onClick={toggleMenu}><i className={rotate ? "icon-close" : "icon-menu"} /></button>}
      {
        show && <Animated
          from={hamburger ? { transform: "translateX(-100%)" } : {}}
          to={hamburger ? { transform: "translateX(0%)" } : {}}
          inverse={open}
        >
          {
            style => <nav style={hamburger ? style : {}}>
              <ul>
                <li>
                  <Link
                    tabIndex={0}
                    to="/"
                    activeClassName="active"
                  >
                    Home
                      </Link>
                </li>
                <li>
                  <Link
                    tabIndex={0}
                    activeClassName="active"
                    to="/works/"
                  >
                    Art Works
                      </Link>
                </li>
                <li>
                  <Link
                    tabIndex={0}
                    activeClassName="active"
                    to="/portfolio/"
                  >
                    Comercial Portfolios
                      </Link>
                </li>
                <li>
                  <Link
                    tabIndex={0}
                    activeClassName="active"
                    to="/studio/"
                  >
                    Studio
                      </Link>
                </li>
                <li className="social">
                  <div className="social-icons">
                    <a aria-label="facebook" rel="noreferrer" tabIndex={0} target="_blank" href="https://www.facebook.com/pages/category/Community-College/Navasardi-Studio-657852430988885">
                      <i className="icon-facebook-logo" />
                    </a>
                    <a aria-label="instagram" rel="noreferrer" tabIndex={0} target="_blank" href="https://www.instagram.com/navasardi">
                      <i className="icon-instagram-logo" />
                    </a>
                    <a aria-label="mail" tabIndex={0} href="mailto:navasardi.arsen@gmail.com">
                      <i className="icon-black-back-closed-envelope-shape" />
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
          }
        </Animated>
      }
    </aside>
  );
}

export default Leftsite;